footer {
  .footer-container {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #1e1e20;
      opacity: 0.8;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    }
  }
  .footer-title {
    display: flex;
    position: relative;
    align-items: center;
    cursor: default;
      img {
        padding-bottom: 2rem !important;
        width: 20%;
        height: auto;
        margin-right: 1rem;
        margin-left: 1rem; /* Adjust margin to create space between image and heading */
      }
    a {
      text-decoration: none;
      color: #fff;
      display: flex; /* Add flex display to align image and heading horizontally */
      align-items: center; /* Vertically center align content */
      &:hover {
        color: #b18b32;
      }
      h6 {
        margin: 0; /* Remove default margin for <h6> element */
      }
  }
}
  .bottom-footer {
    background-color: #0e2b52;
    color: #6c757d;
  }
  .quick-links {
    list-style-type: none;
    padding: 0;
    margin: 0;

    a {
      text-decoration: none;
      font-size: 0.9rem;
      display: inline-block;
      padding: 0.3rem 0;
      color: lighten(#6c757d, 20%) !important;
      &:hover {
        color: #b18b32 !important;
      }
    }
  }
  span {
    font-size: 0.9rem;
    display: block;
    padding: 0.3rem 0;
    color: lighten(#6c757d, 20%) !important;
  }
}
