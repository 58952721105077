#country {
    @keyframes bounceInLeft {
        0% {
           opacity: 0;
           transform: translateX(-2000px);
        }
        60% {
           opacity: 1;
           transform: translateX(30px);
        }
        80% {
           transform: translateX(-10px);
        }
        100% {
           transform: translateX(0);
        }
     } 
     @keyframes bounceInRight {
      0% {
         opacity: 0;
         transform: translateX(2000px);
      }
      60% {
         opacity: 1;
         transform: translateX(-30px);
      }
      80% {
         transform: translateX(10px);
      }
      100% {
         transform: translateX(0);
      }
    } 
    .bounceInLeft {
      -webkit-animation-name: bounceInLeft;
      animation-name: bounceInLeft;
    }
    .bounceInRight {
      -webkit-animation-name: bounceInRight;
      animation-name: bounceInRight;
    }
.details h6 {
    color: var(--blue);
    font-size: 30px;
    font-weight: 500;
    display: block;
    line-height: 1em;
}
.details p {
    font-size: 15px;
}
.listing-item {
   position: relative;
   overflow: hidden;
   border-radius: 30px; }
   .listing-item:after {
     position: absolute;
     content: "";
     left: 0;
     right: 0;
     bottom: 0;
     top: 0;
     z-index: 1;
     background: rgba(0, 0, 0, 0.4); }
   .listing-item img {
     -o-object-fit: cover;
     object-fit: cover;
     height: 190px;
     width: 100%;
     -webkit-transition: .3s all ease;
     -o-transition: .3s all ease;
     transition: .3s all ease;
     -webkit-transform: scale(1.05);
     -ms-transform: scale(1.05);
     transform: scale(1.05); }
   .listing-item:hover img {
     -webkit-transform: scale(1);
     -ms-transform: scale(1);
     transform: scale(1); }
   .listing-item h2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      color: rgb(255, 255, 255);
      text-align: center;
    }
    .listing-item a {
      color: rgb(255, 255, 255);
      cursor: pointer;
      &:hover {
        color: #b18b32;
        text-decoration: none;
      }
    }
}