#about {
  @keyframes bounceInUp {
   0% {
      opacity: 0;
      transform: translateY(2000px);
   }
   60% {
      opacity: 1;
      transform: translateY(-30px);
   }
   80% {
      transform: translateY(10px);
   }
   100% {
      transform: translateY(0);
   }
} 
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
.aboutImage {
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.about-description {
  font-size: 0.9rem;
  color: #6f6f6f;
}
.about-button {
    color: #0e2b52;
    background-color: #F0070700;
    border-color: #0e2b52;
    border-style: solid;
    border-radius: 5px 5px 5px 5px;
    &:hover {
      background-color: #0e2b52;
      color: #fff;
    }
}
}