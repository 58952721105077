#abt {
  @keyframes bounceInLeft {
    0% {
       opacity: 0;
       transform: translateX(-2000px);
    }
    60% {
       opacity: 1;
       transform: translateX(30px);
    }
    80% {
       transform: translateX(-10px);
    }
    100% {
       transform: translateX(0);
    }
 } 
 @keyframes bounceInRight {
  0% {
     opacity: 0;
     transform: translateX(2000px);
  }
  60% {
     opacity: 1;
     transform: translateX(-30px);
  }
  80% {
     transform: translateX(10px);
  }
  100% {
     transform: translateX(0);
  }
} 
.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}
.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}
.bor_header_left {
    position: relative;
    display: inline-block;
    height: 3px;
    background: #b18b32 ;
    width: 40px;
    border-radius: 43px;
    margin-right: 2%;
    top: -3px;
  }
  
  .bor_header_right {
    position: relative;
    display: inline-block;
    height: 3px;
    background: #b18b32 ;
    width: 40px;
    border-radius: 43px;
    margin-left: 2%;
    top: -3px;
  }
  
  .base_header span {
    color: #0e2b52;
    font-size: 36px;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    line-height: 1em;
  }
  
  .base_header h3 {
    margin-top: 2%;
    line-height: 1.2em;
    color: #0e2b52;
    text-transform: capitalize;
    font-weight: 500;
  }
  
  .base_footer p {
    color: #656565;
    line-height: 1.8em;
    font-size: 18px;
    margin-top: 5%;
  }

  .base_footer pre {
    color: #656565;
    line-height: 1.8em;
    font-size: 18px;
    margin-top: 5%;
  }
  
  @media (max-width: 768px) {
    .base_header span {
      font-size: 30px;
    }
  }
  
  @media (max-width: 768px) {
    .base_footer p {
      font-size: 12px;
    }
  }
  
  
}