#navtab {
    .card {
        border: none;
    }
    .card-header {
        background: transparent;
        border-bottom: 1px solid var(--yellow);
    }
    .nav-item a {
        color: var(--blue);
    }
    .nav-item a.active {
        color: var(--yellow);
        border-color: var(--yellow) var(--yellow) #fff;
      }
    .btn {
        background-color: var(--yellow);
        border: transparent;
        border-radius: 15px;
    }
}