#contact {
    margin-top: 117px;

    @media (min-width: 768px) {
      margin-top: 160px;
    } 
  
    .register-photo {
      padding: 30px 0;
    }
    
    .register-photo .image-holder {
      display: table-cell;
      width: auto;
      background: url("../../assets/img/contact4.png");
      background-size: cover;
    }
    
    .register-photo .form-container {
      display: table;
      max-width: 900px;
      width: 90%;
      margin: 0 auto;
      box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
    }
    
    .register-photo form {
      display: table-cell;
      width: 400px;
      background-color: #ffffff;
      padding: 40px 60px;
      color: #0e2b52;
    }
    
    @media (max-width:991px) {
      .register-photo form {
        padding: 40px;
      }
    }
    
    .register-photo form h2 {
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 30px;
      font-family: 'Space Grotesk', sans-serif;
    }
    
    .register-photo form .form-control {
      background: #f7f9fc;
      border: none;
      border-bottom: 1px solid #dfe7f1;
      border-radius: 0;
      box-shadow: none;
      outline: none;
      color: inherit;
      text-indent: 6px;
      height: 40px;
      font-family: 'Space Grotesk', sans-serif;
    }
    
    .register-photo form .form-check {
      font-size: 13px;
      line-height: 20px;
    }
    
    .register-photo form .btn-primary {
      background: #0e2b52;
      border: none;
      border-radius: 4px;
      padding: 11px;
      box-shadow: none;
      margin-top: 35px;
      font-family: 'Space Grotesk', sans-serif;
      text-shadow: none;
      outline: none !important;
    }
    
    .register-photo form .already {
      display: block;
      text-align: center;
      font-size: 12px;
      color: #6f7a85;
      opacity: 0.9;
      text-decoration: none;
    }
    .contact-icons svg {
      font-size: 32px;
      color: #b18b32;
    }
    
    .contact-icons label {
      font-size: 18px;
      padding-left: 2%;
      font-weight: 300;
    }
    .base_footer h2 {
      color: #0e2b52;
      font-size: 30px;
    }
    
  }