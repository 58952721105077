#visa {
    position: relative;
    margin-top: 70px;
    @media (min-width: 768px) {
      margin-top: 135px;
    }
  .card {
    border: transparent;
    width: 18rem;
    border-radius: 10px;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.54);
    box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.54);
  }
 h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: rgb(255, 255, 255);
  }
  a {
    color: rgb(255, 255, 255);
    &:hover {
      color: #b18b32;
      text-decoration: none;
    }
  }
  .card-img-top {
    object-fit: cover;
    height: 100%;
    width: 100%;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    &:hover {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
  }
  .listing-item {
    position: relative;
    overflow: hidden;
    border-radius: 30px; }
    .listing-item:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1;
      background: rgba(0, 0, 0, 0.4); }
    .listing-item img {
      -o-object-fit: cover;
      object-fit: cover;
      height: 190px;
      width: 100%;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease;
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05); }
    .listing-item:hover img {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1); }
}