#services {
  position: relative;
  margin-top: 70px;
  @media (min-width: 768px) {
    margin-top: 130px;
  }
.card {
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  border: none;
  direction: ltr;
  border: 1px solid #eef1f5;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 95%;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .card-title {
    text-align: center;
    color: #0c243e;
    font-size: 1rem;
    font-weight: bolder;
    text-transform: uppercase;
  }
}
.arrow {
  border: none;
  background-color: #0e2b52;
  color: #fefefe;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  height: 30px;
  width: 30px;
  &:hover {
    background-color: #b18b32;
    transform: translateY(-5px);
  }
.card-text {
  font-size: 0.9rem;
  color: #6f6f6f;
  text-align: right !important;
}
.card-body {
  text-align: center;
}
}
.card img {
  height: 200px; /* Set a fixed height for the images */
  object-fit: cover;
}

.bor_header_left {
  position: relative;
  display: inline-block;
  height: 3px;
  background: #b18b32 ;
  width: 40px;
  border-radius: 43px;
  margin-right: 2%;
  top: -3px;
}

.bor_header_right {
  position: relative;
  display: inline-block;
  height: 3px;
  background: #b18b32 ;
  width: 40px;
  border-radius: 43px;
  margin-left: 2%;
  top: -3px;
}

.base_header span {
  color: #0e2b52;
  font-size: 36px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  line-height: 1em;
}
}