.faq {
    width: 100%;
    padding-top: 65px;
    padding-bottom: 80px;
  
    &__title {
      margin: 0;
      padding-bottom: 25px;
    }
  
    &__question {
      width: 100%;
      border-bottom: 1px solid hsl(240, 5%, 91%);
    }
  
    &__question-button {
      color: #0e2b52;
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: none;
      background-color: transparent;
      padding: 0px;
      cursor: pointer;
      font-size: 1.2rem;
      padding-top: 18px;
      padding-bottom: 18px;
  
      &::after {
        content: url(../../assets/img/alt-arrow-down-svgrepo-com.svg);
        margin-right: 17px;
        transform: rotate(90deg);
      }
  
      &:hover {
        color: #b18b32 ;
      }
    }
  
    &__desc {
      padding-right: 17px;
      margin-top: 0;
      margin-bottom: 0;
      height: 0;
      overflow: hidden;
      transition: all 200ms ease-out;
    }
  }
  @media only screen and (max-width: 700px) {

    .faq {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      padding-top: 132px;
      padding-bottom: 48px;
  
  
      &__question-button {
        font-size: 13px;
        line-height: 13px;
  
        &::after {
          padding-right: 0px;
        }
      }
    }
  }
  .faq__desc.show-description {
    height: 3rem;
    margin-bottom: 15px;
  }
  
  /* Arrows */
  
  /* closed */
  .faq__question-button::after {
    transition: transform 300ms ease-out;
  }
  
  /* open */
  .font-weight-bold::after {
    transform: rotate(720deg);
  }

  .bor_header_left {
    position: relative;
    display: inline-block;
    height: 3px;
    background: #b18b32 ;
    width: 40px;
    border-radius: 43px;
    margin-right: 2%;
    top: -3px;
  }
  
  .bor_header_right {
    position: relative;
    display: inline-block;
    height: 3px;
    background: #b18b32 ;
    width: 40px;
    border-radius: 43px;
    margin-left: 2%;
    top: -3px;
  }
  
  .base_header span {
    color: #0e2b52;
    font-size: 36px;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    line-height: 1em;
  }