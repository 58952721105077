#facts {
  @keyframes bounceInUp {
    0% {
       opacity: 0;
       transform: translateY(2000px);
    }
    60% {
       opacity: 1;
       transform: translateY(-30px);
    }
    80% {
       transform: translateY(10px);
    }
    100% {
       transform: translateY(0);
    }
 } 
 .bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
  .facts-container {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #003267;
      opacity: 0.8;
    }
    .facts-icon {
      font-size: 2.5rem;
      color: #F7F9FC;
    }
    .facts-counter {
      font-size: 1.5rem;
      font-weight: 500;
    }
    .facts-title {
    }
  }
}