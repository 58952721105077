#indiavisa{
    position: relative;
    margin-top: 50px;
    @media (min-width: 768px) {
      margin-top: 120px;
    }
    @keyframes moveToRight {
      0% {
          transform: translateX(0px);
      }
      100% {
          transform: translateX(500px);
      }
  }
  .moveToRight {
    -webkit-animation-name: moveToRight;
    animation-name: moveToRight;
  }
    .card {
        width: 250px;
        margin-bottom: 20px;
        box-shadow: 0px 10px 34px rgba(0, 0, 0, 0.02) !important;
        transition: all 0.3s ease;
        border: transparent;
      }
      .section-header h6 {
        font-size: 17px;
      }
      .section-header p {
        font-size: 17px;
      }
      .btn {
        background: var(--yellow);
        height: 40px;        
        border: transparent;
        color: #fff !important;
        display: inline-block !important;
      }
      .btn1 {
        background: var(--blue);
        height: 40px;        
        border: transparent;
        color: #fff !important;
        display: inline-block !important;
      }
      .req {
        color: var(--yellow);
    text-decoration: underline;
      }
      .req2 {
        color: var(--blue);
        text-decoration: underline;
      }
      h2 {
        color: var(--blue);
      }
      p {
        line-height: 30px;
      }
      .nav-link {
        color: var(--blue);
      }
      .nav-item .active{
        color: var(--yellow);
        border-color: var(--yellow) var(--yellow) #fff;
      }
      .nav-tabs {
        border-color: var(--yellow);
      }
      .photo-icon {
        width: auto;
        height: 110px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-bottom: 15px;
        cursor: pointer;
      }
      .card-title {
        color: var(--blue);
      }
      .card-text {
        font-size: 15px;
    }
      @media only screen and (max-width: 767px) {
        .card {
          width: 170px;
        }
        .card-title {
            font-size: 17px;
          }
      }
}