#carousel {
  @keyframes fadeInUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  
  .fadeInUp-animation {
    animation: 1.5s fadeInUp;
  }
    position: relative;
    margin-top: 56px;
  
    @media (min-width: 768px) {
      margin-top: 115px;
    }
   .visually-hidden {
    display: none;
   }
   .carousel-control-prev-icon {
     display: inline-block;
     width: 2rem;
     height: 2rem;
     background-repeat: no-repeat;
     background-position: 50%;
     background-size: 100% 100%;
     background-image:url("../../assets/img/prev.svg");
   }
   .carousel-control-next-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    background-image:url("../../assets/img/next.svg");
  }
  .carousel-caption {
    position: absolute; /* Position text absolutely within the Carousel item */
    bottom: 20%; /* Adjust the distance from the bottom of the image */
    text-align: center; /* Center the text content */
    padding: 10px; /* Add some padding to the text box */
    border-radius: 5px;
    @media (min-width: 768px) {
      white-space: nowrap;
      bottom: 25%;
    }
  }
  
  /* Style for the main heading (h3) inside the Carousel item */
  .carousel-caption h3 {
    font-size: 40px; /* Adjust the font size for the main heading */
    color: #fff; /* Text color for the main heading */
    margin: 0; /* Remove default margin for h3 */
  }
  
  /* Style for the subtitle (p) inside the Carousel item */
  .carousel-caption p {
    font-size: 20px; /* Adjust the font size for the subtitle */
    color: #f4efef; /* Text color for the subtitle */
    margin: 0; /* Remove default margin for p */
  }
  .carousel-caption .custom-button {
    display: inline-block;
    color: #fff; /* Text color */
    padding: 10px 20px; /* Padding around the button text */
    border-radius: 20px; /* Rounded corners */
    border: 2px solid #E8E8E8;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s; /* Smooth background color transition on hover */
  }
  
  /* Hover effect for the custom button */
  .carousel-caption .custom-button:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Darker background on hover */
  }
  .carousel-item {
    background-color: rgba(0, 0, 0, 0.7);
  }
}